@import "@vmf/components/src/assets/style/_index.scss";





























































































.echarts-demo {
    width: 100%;
}
